import { MEETING_METHOD_NAMES, PAGES } from '../constants';
import Api from './Api';

const TRANSACTION_STATUS_START = 'appointment scheduling start';
const TRANSACTION_STATUS_SUBMIT = 'appointment scheduling submit';

export default {
  view(page, trackingData = {}) {
    // Check if this is a duplicate page view (certain pages cause this to be called multiple times)
    const duplicatePageView = page === window.previousIdentifier;
    window.previousIdentifier = page;

    Promise.resolve(trackingData).then(function (trackingData) {
      // Google Tag Manager
      if (window.ga) {
        window.ga('send', 'pageview', { page });
        window.ga('clientTracker.send', 'pageview', { page });
      }

      // Tealium Tag Manager
      if (window.utag && !duplicatePageView) {
        window.reportingData = trackingData;

        // This 'publisherFW' object is exposed via USBank's utag script implementation
        // They are making use of this rather than the generic Tealium utag.view() function
        if (window.publisherFW) {
          window.publisherFW.publishEvent('pageView', window.reportingData);
        } else {
          window.utag.view(trackingData);
        }
      }

      // Adobe Launch Tag Manager
      if (window.adobeTagManager && !duplicatePageView) {
        window.adobeTagManager.push?.(trackingData);
      }
    });
  },

  event(action, category, label = null, value = undefined) {
    if (window.ga) {
      window.ga('send', 'event', category, action, label, value);
    }
  },

  canSubmitConversion() {
    return (
      window.google_trackConversion &&
      window.state.conversion.id &&
      window.state.conversion.label
    );
  },

  submitConversion() {
    window.google_trackConversion({
      google_conversion_id: window.state.conversion.id,
      google_conversion_label: window.state.conversion.label,
      google_conversion_format: '3',
      google_remarketing_only: false,
    });
  },

  getTrackingData(page, firstStep, selections) {
    const trackingData = {
      currentPage: page,
      siteSection: 'tools',
      subSiteSection: 'appointment scheduling',
      sitePlatform: 'dotcom',
      language: selections.locale
        ? selections.locale
        : document.documentElement.getAttribute('lang'),
      datasource: 'pageView',
      appointmentType: MEETING_METHOD_NAMES[selections.meetingMethod],
      appointmentCategory: selections.serviceCategory?.name,
      appointmentSubCategory: selections.service?.name,
      referenceId: selections.confirmCode,
    };

    if (!window.previousIdentifier) {
      trackingData.transactionStatus = TRANSACTION_STATUS_START;
    } else {
      switch (page) {
        case PAGES.CONFIRMATION:
          trackingData.transactionStatus = TRANSACTION_STATUS_SUBMIT;
          break;
        default:
          break;
      }
    }

    if (typeof window.transformReportingData === 'function') {
      return window.transformReportingData(selections, trackingData, Api);
    }

    return trackingData;
  },
};
